"use client";

import { useCallback, useEffect, useState } from "react";

import Image, { type ImageProps } from "next/image";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";

import LogoHorizontalDark from "@public/images/logo/logo-horizontal-dark.svg";
import LogoHorizontalIsolatedDark from "@public/images/logo/logo-horizontal-isolated-dark.svg";
import LogoHorizontalIsolated from "@public/images/logo/logo-horizontal-isolated.svg";
import LogoHorizontal from "@public/images/logo/logo-horizontal.svg";
import LogoDefault from "@public/images/logo/logo.svg";

type LogoProps = Omit<ImageProps, "src" | "alt" | "priority"> & {
  type?: "default" | "horizontal" | "isolated";
  withDarkTheme?: boolean;
  forceDarkTheme?: boolean;
};

const Logo = ({
  type = "default",
  withDarkTheme = true,
  forceDarkTheme = false,
  ...rest
}: LogoProps): JSX.Element => {
  const t = useTranslations("common.logo");
  const { theme } = useTheme();
  const [isDarkLogo, setIsDarkLogo] = useState(forceDarkTheme);

  useEffect(() => {
    withDarkTheme && !forceDarkTheme && setIsDarkLogo(theme === "dark");
  }, [theme, withDarkTheme, forceDarkTheme]);

  const getLogoSrc = useCallback(() => {
    if (type === "horizontal") {
      return isDarkLogo ? LogoHorizontalDark : LogoHorizontal;
    } else if (type === "isolated") {
      return isDarkLogo ? LogoHorizontalIsolatedDark : LogoHorizontalIsolated;
    } else {
      return LogoDefault;
    }
  }, [isDarkLogo, type]);

  return <Image src={getLogoSrc()} priority alt={t("description")} {...rest} />;
};

export default Logo;
